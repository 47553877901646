import axios from 'axios';

// Define the base URL using an environment variable
const POSTCODE_SERVICE_BASE_URL =
  process.env.POSTCODE_SERVICE_BASE_URL ?? 'https://api-prod-staging.portal.flipdishdev.com';

// Define an interface for the expected error response
export interface ErrorResponse {
  error: {
    message: string;
    code: number;
  };
}

export interface PostcodeWKT {
  postcode: string;
  wktList: string[];
}

export interface PostcodePolygonsResponse {
  data: {
    postcodes: PostcodeWKT[];
  };
  metadata: {
    dataSource: string;
    polygonCount: number;
  };
}

export const fetchPostcodePolygonsForPostcodes = async (
  orgId: string,
  postcodes: string
): Promise<string[] | string> => {
  try {
    const response = await axios.get<string>(
      `${POSTCODE_SERVICE_BASE_URL}/postcodes/orgs/${orgId}/polygons?postcodes=${postcodes}`,
      {
        headers: {
          Accept: 'text/plain',
        },
        responseType: 'text',
      }
    );

    return response.data.split('\n').filter((line) => line.trim() !== '');
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.status === 400) {
        const errorData = error.response.data as ErrorResponse;
        return `${errorData.error.message || 'Invalid request'}`;
      }
    }
    console.error('Error fetching polygons for postcodes:', error);
    return [];
  }
};

export const fetchMultiplePostcodePolygonsForPostcodes = async (
  orgId: string,
  postcodes: string
): Promise<PostcodeWKT[] | string> => {
  try {
    const response = await axios.get<PostcodePolygonsResponse>(
      `${POSTCODE_SERVICE_BASE_URL}/postcodes/orgs/${orgId}/postcodePolygons?postcodes=${postcodes}`
    );

    return response.data.data.postcodes;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.status === 400) {
        const errorData = error.response.data as ErrorResponse;
        return `${errorData.error.message || 'Invalid request'}`;
      }
    }
    console.error('Error fetching polygons for postcodes:', error);
    return [];
  }
};
