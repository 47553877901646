import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { AppConfigUpdateModel } from '@flipdish/api-client-typescript/private/api';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';
import { debounce } from 'lodash';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { ExternalLink } from '@fd/ui/atoms/ExternalLink';

import { appsActions } from '../../../actions/apps.actions';
import { usePrevious } from '../../../custom-hooks/usePrevious';
import { myFlipdish } from '../../../helpers/externalLinks';
import { createLoadingSelector } from '../../../selectors/loading.selector';
import { Button } from '../../../ui/Button';
import PageLayout from '../../../ui/Layout';
import FullWidthContainer from '../../../ui/Layout/FullWidthContainer';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import Loading from '../../../ui/Loading';
import { salesChannelsServices } from '../../SalesChannels/salesChannels.services';
import { GetSchemaResponse } from '../../SalesChannels/types';
import {
  checkDNS,
  getAppEmbedState,
  getDNSStatus,
  resetDnsRecords,
  resetWebsiteStatusFields,
  SET_HOSTNAME_APP_CONFIG,
  setAppConfig,
  setHostNameAppConfig,
} from '../actions';
import { domainFormatCheck } from '../helpers';
import { DNSRecord, RequestStatus } from '../reducer';
import { getDNSRecords, getHasSubdomain, getIsEmbed } from '../selectors';
import services from '../service';
import DnsRecords from './DnsRecords';
import ReconfigureDomainSetup from './ReconfigureDomainSetup';
import Steps from './Steps';
import WebEmbedCode from './WebEmbedCode';

const updateLoadingSelector = createLoadingSelector([SET_HOSTNAME_APP_CONFIG]);
let lastRequestTime: number;

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    '& a': {
      textDecoration: 'none',
    },
    marginBottom: 24,
  },
  row: {
    display: 'flex',
    alignItems: 'self-start',
  },

  stepper: {
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
  separator: {
    margin: spacing(3, 0),
  },
  stepsWrapper: {
    flexGrow: 1,
    boxShadow: 'none',
    paddingRight: 0,
    margin: '0 !important',

    '&:before': {
      display: 'none',
    },
  },
  header: {
    flexGrow: 1,
  },
  panelSummary: {
    padding: 0,
  },
  panelDetails: {
    padding: 0,
  },
  subHeader: {
    display: 'block',
    marginBottom: 12,
  },

  status: {
    marginBottom: 24,
  },
  radio: {
    marginLeft: -9,
  },
  noBottomPadding: {
    paddingBottom: '0px !important',
  },
}));

type InnerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
type OuterProps = {};
type Props = InnerProps & OuterProps;

const WebsiteDomainSetup = (props: Props) => {
  const {
    checkDNS,
    currentApp,
    getAppIsEmbed,
    getDNSStatus,
    hasSubdomain,
    isEmbed,
    pageLoading,
    records,
    resetDnsRecords,
    setAppConfig,
    setHostName,
    setUrl,
    translate,
    dispatchResetWebsiteStatusFields,
    setHostnameRequestStatus,
  } = props;
  const classes = useStyles();
  const previousHostName = usePrevious(currentApp.HostName);

  //#region USE STATE
  const [activeStep, setActiveStep] = useState<number>(-1);
  const [panaceaUrl, setPanaceaUrl] = useState<string | undefined>('');
  const [available, setAvailable] = useState(false);
  const [checking, setChecking] = useState(false);
  const [useOwnDomain, setUseOwnDomain] = useState<boolean>(false);
  const [stepsCompleted, setStepsCompleted] = useState<boolean>(true);
  const [reconfigureDomainSetupClicked, setReconfigureDomainSetupClicked] = useState(false);
  const [schema, setSchema] = useState<GetSchemaResponse | undefined>(undefined);
  //#endregion

  const isTouched = currentApp.panaceaVanityUrl !== panaceaUrl && panaceaUrl;
  const Loader = () => (
    <Grid key="loader" container justifyContent="center">
      <Loading />
    </Grid>
  );

  //#region DNS records
  const visibleRecords = useMemo(() => {
    // filter out 'A' record if it's a subdomain
    // non subdomains are always returned from the API with www
    let DNSRecords: DNSRecord[] = records;
    if (!currentApp.HostName?.startsWith('www')) {
      DNSRecords = records.filter((r) => r.Type !== 'A');
    }
    if (!isEmbed) {
      const d = domainFormatCheck(currentApp.HostName!, hasSubdomain);
      return DNSRecords.filter((r) => r.Target !== 'websites-api-prod-staging.portal.flipdishdev.comm').map((r) => {
        if (r.Host === 'www') {
          return {
            ...r,
            Host: d,
          };
        }
        if (r.Host === '@') {
          return {
            ...r,
            Host: d === 'www' ? r.Host : d?.split('.')[1],
          };
        }
        return r;
      });
    } else {
      return DNSRecords.filter((r) => r.Target === 'websites-api-prod-staging.portal.flipdishdev.comm').map((r) => {
        if (currentApp.HostName && r.Host === 'flipdish-api') {
          return {
            ...r,
            Host: `${r.Host}.${domainFormatCheck(currentApp.HostName, hasSubdomain)}`,
          };
        }
        return r;
      });
    }
  }, [records, isEmbed, currentApp.HostName]);

  const isDNSReady = useMemo(() => visibleRecords.every((item) => item.Status), [visibleRecords]);

  const checkAvailability = useMemo(
    () =>
      debounce(async (appId: string, url: string) => {
        const time = Date.now();

        try {
          lastRequestTime = time;
          const { Data } = await services.getPanaceaUrlStatus(url, appId);
          if (time === lastRequestTime) {
            setAvailable(Data === 'True');
            setChecking(false);
          }
        } catch (e) {
          if (time === lastRequestTime) {
            setAvailable(false);
            setChecking(false);
            console.log(e);
          }
        }
      }, 1000),
    []
  );
  //#endregion

  //#region USE EFFECTS
  useEffect(() => {
    getDNSStatus();
  }, []);

  useEffect(() => {
    if (currentApp.HostName && currentApp.HostName !== previousHostName) {
      if (records.find((item) => item.Type === 'CNAME')!.Status) {
        checkDNS();
      }
    }
  }, [currentApp.HostName, previousHostName]);
  useEffect(() => {
    if (currentApp.AppId && isTouched && panaceaUrl && panaceaUrl.length >= 3) {
      checkAvailability(currentApp.AppId, panaceaUrl);
    } else {
      setChecking(false);
      setAvailable(false);
    }
  }, [panaceaUrl, currentApp.AppId]);
  useEffect(() => {
    setPanaceaUrl(currentApp.panaceaVanityUrl || '');
  }, [currentApp.panaceaVanityUrl]);

  useEffect(() => {
    getAppIsEmbed();
  }, [currentApp.HostName]);
  //#endregion

  const flipdishUrl = myFlipdish(panaceaUrl);
  const isPanaceaCustom: boolean = useMemo(() => {
    return panaceaUrl !== currentApp.AppId;
  }, [panaceaUrl, currentApp.AppId]);

  //#region VALIDATION
  const showIndicators = isTouched && !checking;
  let icon: null | ReactNode = null;
  let helperText: null | ReactNode = null;
  if (showIndicators) {
    icon = available ? (
      <CheckIcon color="primary" />
    ) : (
      <CancelIcon color="error" onClick={() => setPanaceaUrl('')} />
    );
    helperText = (
      <span style={{ color: available ? '#1dc798' : '#e5003e' }}>
        {available ? translate('Available') : translate('Already_taken')}
      </span>
    );
  }
  //#endregion

  const resetSteps = () => {
    setStepsCompleted(true);
    setReconfigureDomainSetupClicked(false);
  };

  useEffect(() => {
    if (reconfigureDomainSetupClicked) {
      if (setHostnameRequestStatus === RequestStatus.SUCCESS) {
        setAppConfig({ IsPanaceaEnabled: true });
        resetSteps();
      } else if (setHostnameRequestStatus === RequestStatus.ERROR) {
        dispatchResetWebsiteStatusFields();
        resetSteps();
      }
    }
  }, [setHostnameRequestStatus, reconfigureDomainSetupClicked]);

  const {
    data,
    isPending: isSchemaLoading,
    isError,
    isSuccess,
  } = useQuery({
    queryKey: ['getSchema', currentApp?.AppId],
    queryFn: () => salesChannelsServices.getSchema(currentApp.AppId || ''),
    gcTime: 1000 * 30,
    enabled: !!currentApp?.AppId,
    retry: false,
  });
  useEffect(() => {
    if (isError) {
      setSchema(undefined);
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess && data) {
      setSchema(data);
    }
  }, [isSuccess, data]);

  const handleReset = () => {
    setReconfigureDomainSetupClicked(true);
    resetDnsRecords();
    if (currentApp.HostName) {
      setHostName('', false, false);
    }
    setActiveStep(0);
    setStepsCompleted(false);
  };

  return (
    <PageLayout
      toParent={`/${currentApp.AppId}/sales-channels/website?tab=settings`}
      strictToParent
      title={translate('Domain_setup')}
      caption={translate('Domain_setup_caption')}
      documentTitle="Domain_setup"
    >
      {pageLoading || isSchemaLoading ? (
        Loader()
      ) : (
        <>
          <PaperContainer className={classes.root}>
            <FullWidthContainer fullSize noTopSpace className={classes.noBottomPadding}>
              <Typography variant="subtitle1">
                {schema && !schema?.hasLiveSite ? (
                  <>
                    {translate('Publish_your_website_to_activate_domains')}{' '}
                    <ExternalLink href="https://help.flipdish.com/s/article/Your-Flipdish-website-and-website-builder">
                      {translate('Learn_more')}
                    </ExternalLink>
                  </>
                ) : (
                  translate('Website_available_at', {
                    url: currentApp.HostName ? `https://${currentApp.HostName}` : flipdishUrl,
                  })
                )}
              </Typography>

              {/* STATUS MSG */}
              {stepsCompleted && currentApp.HostName && (
                <Typography variant="caption">
                  {translate(isDNSReady ? 'DNS_ready' : 'DNS_not_ready')}
                </Typography>
              )}

              <div className={classes.separator}>
                <Divider />
              </div>
              {/* CONFIGURE */}
              {!isPanaceaCustom && !currentApp.HostName && stepsCompleted && !pageLoading && (
                <Button
                  fdKey="configure_domain_btn"
                  variant="contained"
                  color="primary"
                  style={{ width: 194 }}
                  onClick={() => {
                    setStepsCompleted(false);
                    setActiveStep(0);
                  }}
                >
                  {translate('Configure')}
                </Button>
              )}

              {/* I WANNA USE MY OWN DOMAIN */}
              {isPanaceaCustom && !currentApp.HostName && stepsCompleted && (
                <Button
                  fdKey="I_want_to_use_my_domain_btn"
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setUseOwnDomain(true);
                    setStepsCompleted(false);
                    setActiveStep(1);
                  }}
                >
                  {translate('I_wanna_use_my_own_domain')}
                </Button>
              )}
            </FullWidthContainer>

            {/* STEPS */}
            <div className={classes.row}>
              {!stepsCompleted && (
                <Steps
                  activeStep={activeStep}
                  appId={currentApp.AppId}
                  available={available}
                  checking={checking}
                  currentPanacea={currentApp.panaceaVanityUrl}
                  getDNSStatus={getDNSStatus}
                  helperText={helperText}
                  icon={icon}
                  panaceaUrl={panaceaUrl}
                  resetDnsRecords={resetDnsRecords}
                  setUseOwnDomain={setUseOwnDomain}
                  setActiveStep={setActiveStep}
                  setAppConfig={setAppConfig}
                  setChecking={setChecking}
                  setHostName={setHostName}
                  setPanaceaUrl={setPanaceaUrl}
                  setStepsCompleted={setStepsCompleted}
                  setUrl={setUrl}
                  useOwnDomain={useOwnDomain}
                  dispatchResetWebsiteStatusFields={dispatchResetWebsiteStatusFields}
                  reconfigureDomainSetupClicked={reconfigureDomainSetupClicked}
                />
              )}
            </div>
            {currentApp.HostName && !currentApp.IsPanaceaEnabled && stepsCompleted && (
              <>
                {isEmbed ? (
                  <>
                    <Typography variant="subtitle2" style={{ marginBottom: 8 }}>
                      {translate('Embed_code')}
                    </Typography>
                    <WebEmbedCode domain={currentApp.HostName} />
                  </>
                ) : (
                  <DnsRecords getDNSStatus={getDNSStatus} visibleRecords={visibleRecords} />
                )}
              </>
            )}
          </PaperContainer>

          {(currentApp.HostName || isPanaceaCustom) && stepsCompleted && (
            <ReconfigureDomainSetup handleReset={handleReset} />
          )}
        </>
      )}
    </PageLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  currentApp: state.currentApp,
  isEmbed: getIsEmbed(state),
  pageLoading: state.website.pageLoading,
  records: getDNSRecords(state),
  saving: updateLoadingSelector(state),
  translate: getTranslate(state.locale),
  hasSubdomain: getHasSubdomain(state),
  setHostnameRequestStatus: state.website?.setHostnameRequestStatus,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  setAppConfig: (config: AppConfigUpdateModel) => dispatch(setAppConfig(config)),
  getAppIsEmbed: () => dispatch(getAppEmbedState()),
  checkDNS: () => dispatch(checkDNS()),
  getDNSStatus: () => dispatch(getDNSStatus()),
  resetDnsRecords: () => dispatch(resetDnsRecords()),
  setHostName: (hostname: string, isEmbed: boolean, hasSubdomain: boolean) =>
    dispatch(setHostNameAppConfig(hostname, isEmbed, hasSubdomain)),
  setUrl: (appId: string, url: string) => dispatch(appsActions.setPanaceaVanityUrl(appId, url)),
  dispatchResetWebsiteStatusFields: () => dispatch(resetWebsiteStatusFields()),
});

export default compose<InnerProps, OuterProps>(connect(mapStateToProps, mapDispatchToProps))(
  WebsiteDomainSetup
);
