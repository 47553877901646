import { datadogRum, DefaultPrivacyLevel, RumInitConfiguration } from '@datadog/browser-rum';

import constants from '../../constants.json';
import { AccountState } from '../../reducers/account.reducer';
import { KNOWN_ERRORS_TO_IGNORE } from './knownErrorToIgnore';

const DATADOG_APPLICATION_ID = process.env.VITE_DATADOG_APPLICATION_ID;
const DATADOG_CLIENT_TOKEN = 'pubd9df1ad418728dbcd3a4f39e2899261c';

let _disabled_ = false;
let _initialized_ = false;

export const initializeDatadogRum = (disable = false) => {
  if (document.cookie.indexOf('_dd_s') === -1 && disable) {
    datadogRum.clearUser();
    _initialized_ = false;
    return;
  }

  if (!DATADOG_APPLICATION_ID || !DATADOG_CLIENT_TOKEN) {
    if (process.env.NODE_ENV === 'development') {
      console.warn(
        `[Datadog RUM] To enable Datadog RUM in dev time, add 'VITE_DATADOG_APPLICATION_ID', 'REACT_DATADOG_CLIENT_TOKEN',' to '.env.development.local' file`
      );
    }
    return;
  }

  _disabled_ = disable;

  if (_initialized_) {
    return;
  }
  _initialized_ = false;

  const env =
    constants.ENV_NAME === 'production'
      ? window.location.hostname.endsWith('.portal.flipdishdev.com')
        ? window.location.hostname.replace('.portal.flipdishdev.com', '')
        : 'production'
      : constants.ENV_NAME;

  const config: RumInitConfiguration = {
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'us3.datadoghq.com',
    service: 'web-portal',
    env,
    version: constants.VERSION,
    sessionSampleRate: env == 'production' ? 100 : 10,
    sessionReplaySampleRate: env == 'production' ? 20 : 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
    allowedTracingUrls: [
      'https://api-prod-staging.portal.flipdishdev.com',
      /https:\/\/api-[A-z\-.]*\.flipdishdev\.com/,
    ],
    traceSampleRate: 100,
    beforeSend: (event, context) => {
      if (
        event.type === 'error' &&
        KNOWN_ERRORS_TO_IGNORE.some((knownError) => event.error.message.includes(knownError))
      ) {
        return false;
      }
      return true;
    },
  };

  datadogRum.init(config);

  _initialized_ = true;
};

const isDateInLastXHours = (dateString: Date, hours: number) => {
  const createdDate = new Date(dateString);
  const now = new Date();
  return now.getTime() - createdDate.getTime() < hours * 60 * 60 * 1000;
};

const formatDateYYYYMMDD = (dateString: Date) => {
  const date = new Date(dateString);
  return date.toISOString().split('T')[0];
};

export const sendDatadogRumData = (account: AccountState) => {
  if (DATADOG_APPLICATION_ID && DATADOG_CLIENT_TOKEN && !_disabled_) {
    const createdInLastDay = account?.CreatedAt ? isDateInLastXHours(account.CreatedAt, 24) : false;
    const createdInLastHour = account?.CreatedAt ? isDateInLastXHours(account.CreatedAt, 1) : false;
    const creationDay = account?.CreatedAt ? formatDateYYYYMMDD(account.CreatedAt) : '';

    const telemetryProps = {
      id: account?.AccountId?.toString() ?? '<NO_ACCOUNT_ID>',
      name: account?.Name,
      email: account?.Email,
      is_flipdish: account?.Email?.includes('@flipdish') ?? false,
      created_in_last_day: createdInLastDay,
      created_in_last_hour: createdInLastHour,
      creation_day: creationDay,
    };

    datadogRum.setUser(telemetryProps);
    datadogRum.setGlobalContext(telemetryProps);
  }
};

export const datadogRUM = {
  initialize: initializeDatadogRum,
  sendData: sendDatadogRumData,
};
